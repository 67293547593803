<script>
  import { essays } from "./store";
  import { onMount } from "svelte";
  import Menu from "./menu.svelte";

  const rootMenu = {
    title: "Logs",
    children: [
      { title: "Software Dev.", children: [] },
      { title: "Hardware Dev.", children: [] },
      { title: "Workplace Ethology", children: [] },
      { title: "Homeplace Metrology", children: [] },
      { title: "개인사", children: [] },
    ],
  };

  async function getEssay() {
    const response = await fetch("/get_essays/", { method: "GET" })
      .then((response) => response.json())
      .then((data) => {
        $essays = Object.values(data);
      });
  }

  onMount(async () => {
    if (!$essays) {
      await getEssay();

      await $essays.forEach((essay) => {
        if (essay.category_id == "Software Dev.") {
          rootMenu.children[0].children.unshift(essay);
        } else if (essay.category_id == "Hardware Dev.") {
          rootMenu.children[1].children.unshift(essay);
        } else if (essay.category_id == "Workplace Ethology") {
          rootMenu.children[2].children.unshift(essay);
        } else if (essay.category_id == "Homeplace Metrology") {
          rootMenu.children[3].children.unshift(essay);
        } else if (essay.category_id == "개인사") {
          rootMenu.children[4].children.unshift(essay);
        }
      });

      const menu = document.getElementById("menu");
      new Menu({ target: menu, props: { rootMenu: rootMenu } });
    }
  });
</script>

<div id="menu" />

<main>
  {#if $essays}
    {#each $essays as essay}
      <section id="essay{essay.id}">
        <h1>{essay.title}</h1>
        {@html essay.text}<br />
        <span class="essayInfo">
          Category: {essay.category_id}<br />
          Last modified: {essay.last_modified_date}<br />
        </span>
      </section>
      <hr />
    {/each}
  {/if}
</main>

<style>
  :global(body) {
    display: grid;
    grid-template-columns: 20% 80%;
  }

  :global(a) {
    text-decoration: none;
    color: inherit;
  }

  :global(img) {
    max-width: 100%;
    /* max-height: 50% */
  }

  main {
    text-align: center;
    padding: 1em;
    max-width: 100%;
    margin: 0 auto;
    grid-column: 2;
    width: 100%;
    font-family: "NanumSquareNeo", "Arial";
    font-size: 1.2rem;
  }

  h1 {
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 4rem;
    font-weight: 700;
    line-height: 10rem;
  }

  @media (min-width: 10rem) {
    main {
      max-width: 90%;
    }
  }

  hr {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  span.essayInfo {
    font-size: 0.6rem;
  }

  #menu {
    grid-column: 1;
    width: 20%;
    height: 100%;
    position: fixed;
    left: 0%;
    top: 0%;
  }
</style>
