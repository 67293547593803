<script context="module">
  // retain module scoped expansion state for each tree node
  const _expansionState = {
    Logs: true,
    /* treeNodeId: expanded <boolean> */
  };
</script>

<script>
  import { slide } from "svelte/transition";
  export let rootMenu;

  let expanded = _expansionState[rootMenu.title] || false;
  const toggleExpansion = () => {
    expanded = _expansionState[rootMenu.title] = !expanded;
  };
  $: folderDown = expanded;

  function scrollIntoView({ target }) {
    const el = document.querySelector(target.getAttribute("href"));
    if (!el) return;
    el.scrollIntoView({
      behavior: "smooth",
    });
  }
</script>

<ul>
  <li transition:slide>
    {#if rootMenu.children && rootMenu.children.length > 0}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <span on:click={toggleExpansion}>
        <span class="folder" class:folderDown>
          {#if !expanded}
            <i class="fa-solid fa-folder" />
          {:else}
            <i class="fa-solid fa-folder-open" />
          {/if}
        </span>
        {rootMenu.title}
      </span>
      {#if expanded}
        {#each rootMenu.children as child}
          <svelte:self rootMenu={child} />
        {/each}
      {/if}
    {:else}
      <span>
        <span class="no-folder" />
        {#if rootMenu.id}
          <a
            href="#essay{rootMenu.id}"
            on:click|preventDefault={scrollIntoView}
          >
            {rootMenu.title}
          </a>
        {:else}
          {rootMenu.title}
        {/if}
      </span>
    {/if}
  </li>
</ul>

<style>
  ul {
    margin: 0;
    list-style: none;
    padding-left: 1.2rem;
    user-select: none;
    /* background-color: rgba(255, 200, 200, 0.5); */
    font-size: 1.2rem;
    font-weight: 100;
    font-family: "NanumSquareBold", "Times New Roman";
    padding-top: 2rem;
  }
  .fa-solid {
    color: rgba(30, 39, 34, 0.8);
  }
  .no-folder {
    padding-left: 1rem;
  }
  .folder {
    cursor: pointer;
    display: inline-block;
    transition: transform 200ms;
  }
  .folderDown {
    transform: rotate(15deg);
  }
</style>
